import React from "react"
import Head from "../components/Head"
import Layout from "../components/Layout"

const quienesSomos = () => (
  <>
    <Head
      description="Error 404, no econtramos lo que buscas"
      title="404 - Asadero RoqueFer"
    />
    <Layout>
      <main>
        <h1>NO ENCONTRAMOS LO QUE BUSCAS :(</h1>
      </main>
    </Layout>
  </>
)

export default quienesSomos
